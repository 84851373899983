import { Divider, Stack, Typography } from "@mui/material";
import React from "react";
import {
  background_footer,
  femmes_de_bretagne,
  le_poool,
  logo,
} from "../../config/config";

import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";

const Footer = () => {
  return (
    <Stack
      className="footer"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        bgcolor: "primary.main",
        minHeight: "fit-content",
        height: "fit-content",
        color: "white",
        background: "linear-gradient(#006DB8, #053162)",
        backgroundImage: `url(${background_footer})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <img src={logo} alt="logo" style={{ width: "100%", maxWidth: "260px" }} />
      <Stack
        direction={{ sm: "column", md: "row" }}
        spacing={6}
        px={{ xs: "1em", md: "0 " }}
        mt={4}
        mb={4}
        sx={{ gap: { xs: "16px", sm: "24px", md: 0 }, maxWidth: "100%" }}
      >
        <Stack spacing={2}>
          <Typography variant="h6">Contact</Typography>
          <a href="tel:+330659102660">
            <LocalPhoneIcon sx={{ mr: 2 }} />
            06 59 10 26 60
          </a>
          <a href="mailto:ntessier@talentsetpaillettes.com">
            <MailOutlineIcon sx={{ mr: 2 }} />
            ntessier@talentsetpaillettes.com
          </a>

          <Stack
            alignItems="center"
            justifyContent="center"
            direction="row"
            flexWrap="wrap"
            spacing={2}
            className="social"
          >
            <FacebookIcon />
            <LinkedInIcon />
            <InstagramIcon />
          </Stack>
          <a
            href="https://talentsetpaillettes.com/mentions-legales/"
            target="_blank"
            rel="noreferrer"
          >
            Mentions légales
          </a>
          <a
            href="https://talentsetpaillettes.com/conditions-generales-de-vente/"
            target="_blank"
            rel="noreferrer"
          >
            Conditions Générales de Vente
          </a>
          <a
            href="https://talentsetpaillettes.com/politique-protection-donnees-personnelles/"
            target="_blank"
            rel="noreferrer"
          >
            Politique de Protection des Données personnelles
          </a>
        </Stack>

        <Divider
          orientation="vertical"
          flexItem
          style={{ backgroundColor: "white" }}
        />

        <Stack spacing={2}>
          <Typography variant="h6">Infos</Typography>
          <Typography variant="h5">Horaires d'ouverture</Typography>
          <Typography>Lundi au vendredi : 9h/19h</Typography>
          <Typography>Samedi : 9h/13h</Typography>
          <Typography variant="h5">Lieux des RDV :</Typography>
          <Typography>En distanciel en visio/téléphone</Typography>
          <Stack direction="row" spacing={2}>
            <img
              src={femmes_de_bretagne}
              alt="telephone"
              style={{
                width: "50px",
                aspectRatio: "1/1",
                objectFit: "contain",
              }}
            />
            <img
              src={le_poool}
              alt="le poool"
              style={{
                width: "50px",
                aspectRatio: "1/1",
                objectFit: "contain",
              }}
            />
          </Stack>
        </Stack>

        <Divider
          orientation="vertical"
          flexItem
          style={{ backgroundColor: "white" }}
        />

        <Stack spacing={2}>
          <Typography variant="h6">Nous situer</Typography>
          <Typography variant="h5">35850 Romillé</Typography>
        </Stack>
      </Stack>

      <Typography
        variant="span"
        px={{ xs: "1em", md: "0 " }}
        sx={{
          fontSize: {
            xs: "10px !important",
            sm: "12px !important",
            md: "16px !important",
          },
        }}
      >
        © Talents & Paillettes 2024
      </Typography>
    </Stack>
  );
};

export default Footer;
