import React, { Fragment, useContext, useState, useEffect } from "react";
import { useFetch } from "../../utils/hooks/fetch";
import { UserContext } from "../../contexts";

export default function HiddenFile() {
  const { user, setUser } = useContext(UserContext);
  const { patch, data } = useFetch(true);
  const [profilePicFile, setProfilePicFile] = useState();

  const handleSendProfilPic = (e) => {
    const data = new FormData();
    data.append("profile_picture", profilePicFile);

    const uri =
      user.user_type === "recruiter"
        ? `/recruiter/${user.id}?update_pp=true`
        : `/user/${user.id}?update_pp=true`;
    patch(uri, data, false);
  };

  useEffect(() => {
    if (data) {
      setUser(data);
    }
    if (profilePicFile) {
      handleSendProfilPic();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, profilePicFile]);

  return (
    <Fragment>
      <input
        type="file"
        id="hidden-input-file"
        accept="image/*"
        name=""
        style={{ display: "none", position: "absolute", zIndex: "-1" }}
        onChange={(e) => {
          setProfilePicFile(e.target.files[0]);
        }}
      />

      <label
        htmlFor="hidden-input-file"
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          cursor: "pointer",
        }}
      ></label>
    </Fragment>
  );
}
