export const citizenshipValueToText = (value) => {
  switch (value) {
    case "french":
      return "Française";
    case "eu_expat":
      return "Ressortissant de UE";
    case "external_expat":
      return "Ressortissant hors UE";
    default:
      return "";
  }
};

export const genderValueToText = (value) => {
  switch (value) {
    case "male":
      return "Homme";
    case "female":
      return "Femme";
    case "ungendered":
      return "Non genré";
    default:
      return "";
  }
};

/**
 * Formate le [Tableau de [Tableau]] en [Tableau d'{Objet}]
 * ! Attention ! L'objet retouné doit respecter le même ordre ques
 * def work_exp_format dans candidate.rb dans le back-end.
 *
 * @param {Array<Array>} workExperience - Tableau de Tableau [[Array<Array>1],[Array2],...]
 * @returns {Array<Object>} - Tableau d'objet [{Object1}, {Object2},...].
 */
export const formatWorkExperience = (workExperience) => {
  return workExperience.map((we) => {
    return {
      id: we[0],
      company: we[1],
      job_position: we[2],
      description: we[3],
      start_year: we[4],
      end_year: we[5],
    };
  });
};

/**
 * Formate le [Tableau de [Tableau]] en [Tableau d'{Objet}]
 * ! Attention ! L'objet retouné doit respecter le même ordre ques
 * def educ_format dans candidate.rb dans le back-end.
 *
 * @param {Array<Array>} workExperience - Tableau de Tableau [[Array<Array>1],[Array2],...]
 * @returns {Array<Object>} - Tableau d'objet [{Object1}, {Object2},...].
 */
export const formatEducations = (education) => {
  return education.map((value) => {
    return {
      id: value[0],
      school: value[1],
      training_course: value[2],
      start_year: value[3],
      end_year: value[4],
    };
  });
};

/**
 * Traduit les termes de status des transactions en francais
 *
 * @param {String} status - Chaine de caractères
 * @returns {String} - Chaine de caractères
 */
export const formatTransactionStatus = (status) => {
  switch (status) {
    case "created":
      return "Crée";
    case "waiting":
      return "En attente";
    case "paid":
      return "Payée";
    case "cancelled":
      return "Annulée";
    default:
      return "";
  }
};

/**
 * Formate la date aaaa/mm en mm/aaaa
 *
 * @param {String} date - Chaine de caractères, date sous le format aaaa/mm
 * @returns {String} - Chaine de caractères
 */

export const formatDate = (dateString) => {
  const [year, month] = dateString.includes("/")
    ? dateString.split("/")
    : dateString.split("-");
  if (year === undefined || month === undefined) {
    return `${year}`;
  }

  return `${month}/${year}`;
};

/**
 * Formate les types de transaction en français
 *
 * @param {String} date - Chaine de caractère
 * @returns {String} - Chaine de caractères
 */

export const formatTransactionType = (transaction_type) => {
  switch (transaction_type) {
    case "cv_purchased":
      return "CV";
    case "credits_1":
    case "credits_2":
    case "credits_3":
    case "credits_4":
      return "Crédits";
    default:
      return "";
  }
};
