import React, { useEffect, useState } from "react";
import { Button, Card, Modal, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Confetti from "react-confetti";

const PopUpStepValidation = ({
  badge,
  title,
  description,
  open,
  handleClose,
}) => {
  const [confetti, setConfetti] = useState(false);

  useEffect(() => {
    if (open) {
      setConfetti(true);
      setTimeout(() => setConfetti(false), 4000); // confetti explosion for 2 seconds
    } else {
      setConfetti(false);
    }
  }, [open]);

  return (
    <>
      {confetti && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 10,
          }}
        >
          <Confetti gravity={0.2} numberOfPieces={400} run={confetti} />
        </div>
      )}
      <Modal open={open} onClose={handleClose}>
        <Card
          style={{
            maxWidth: "95vw",
            minWidth: "280px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Button
            onClick={handleClose}
            style={{ position: "absolute", right: 0, top: 0 }}
          >
            <CloseIcon />
          </Button>
          <Stack justifyContent="center" alignItems="center" spacing={3}>
            <Typography variant="h4">Félicitations {title} !</Typography>
            <img src={badge} alt="badge" className="badge" />
            <Typography variant="body1">{description}</Typography>
            <Typography variant="body1">Continuez ainsi !</Typography>
          </Stack>
        </Card>
      </Modal>
    </>
  );
};

export default PopUpStepValidation;
