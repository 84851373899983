import { IconButton, Tooltip, useMediaQuery } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import React, { useState } from "react";
import { useTheme } from "@emotion/react";

/**
 * Composant info-bulle
 *
 * @param {String} title - Text explicatif de l'info-bulle
 * @param {String} placement - Position du text par rapport à l'infobulle, valeurs acceptées:
 * - 'bottom-start',
 * - 'bottom',
 * - 'left-end',
 * - 'left-start',
 * - 'left',
 * - 'right-end',
 * - 'right-start',
 * - 'right',
 * - 'top-end',
 * - 'top-start',
 * - 'top'
 */
const CustomToolTip = ({ title, placement }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.up("sm"));
  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const handleTooltipClose = () => {
    setOpen(false);
  };
  return (
    <Tooltip
      title={title}
      placement={placement}
      open={!isMobile ? open : undefined}
      onClose={handleTooltipClose}
      onClick={!isMobile ? handleTooltipOpen : undefined}
      disableHoverListener={!isMobile}
    >
      <IconButton>
        <InfoIcon color="primary" />
      </IconButton>
    </Tooltip>
  );
};

export default CustomToolTip;
