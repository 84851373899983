import React, { useContext, useEffect } from "react";
import { Divider, Link } from "@mui/material";
import { useFetch } from "../../utils/hooks/fetch";
import { UserContext } from "../../contexts";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const { user } = useContext(UserContext);

  const { post, data } = useFetch();

  useEffect(() => {
    if (data?.message) {
      toast.success(data?.message);
    } else if (data?.error) {
      toast.error(data?.error);
    }
  }, [data]);

  return (
    <>
      <Divider
        orientation="vertical"
        flexItem
        sx={{
          borderColor: "rgba(0 ,0, 0, 0.2) !important",
          borderWidth: "1px !important",
          margin: "0 20px",
          my: 2,
        }}
      />
      <Link
        onClick={() =>
          post(`/auth/forgot_password`, { email: user.email }, true)
        }
        sx={{ cursor: "pointer !important", alignSelf: "end" }}
      >
        Changer mon mot de passe
      </Link>
    </>
  );
};

export default ChangePassword;
