import React, { Fragment, useContext, useState, useEffect } from "react";
import {
  Avatar,
  Badge,
  Button,
  Drawer,
  MenuItem,
  Stack,
  Typography,
  Checkbox,
  FormGroup,
  Grid,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { CartContext, UserContext } from "../../contexts";
import { coin } from "../../config/config";
import { Link, useNavigate } from "react-router-dom";
import { useFetch } from "../../utils/hooks/fetch";
import PaimentProcess from "./paiment_process";

const CartDrawer = () => {
  const navigate = useNavigate();

  const { user } = useContext(UserContext);
  const { cart, setCart } = useContext(CartContext);

  const [useCredits, setUseCredits] = useState(false);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openTransfer, setOpenTransfer] = useState(false);
  const [subProcess, setSubProcess] = useState(false);
  const transferAmount = useCredits
    ? (cart?.candidates.length - cart?.available_credits) * 100
    : cart?.total_price;

  const { data: currentCart, destroy } = useFetch(true);
  const {
    data: checkPaidProcess,
    post: postInitCheck,
    loading: loadingInitCheck,
  } = useFetch(true);
  const {
    data: creditsProcess,
    post: postCredits,
    loading: loadingCredits,
  } = useFetch(true);
  const { data: transferProcess, post: postTransfer } = useFetch(true);

  // Gérer la suppression d'un CV du panier
  const removeCandFromCart = (cv) => {
    destroy(
      `/cart_item/${user.id}`,
      { cart_item: { candidate_id: cv.candidate_id } },
      true
    );
  };

  // Gérer l'affichage du panier
  useEffect(() => {
    if (currentCart?.success) {
      setCart(currentCart.cart);
    }
    if (currentCart?.error) {
      toast.info(currentCart.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCart]);

  const handleInitCheck = (_, transfer = false) => {
    postInitCheck(`/initialize-checkout`, {
      user: user,
      useCredits: useCredits,
      transfer: transfer,
    });
  };

  useEffect(() => {
    if (checkPaidProcess) {
      switch (checkPaidProcess.purchase_type) {
        case "by_stripe":
          setOpenModal(true);
          break;
        case "by_credits":
          setOpenDialog(true);
          break;
        case "by_transfer":
          setOpenTransfer(true);
          break;
        case "by_subscription":
          setSubProcess(true);
          break;
        default:
          toast.error(
            "Excusez-nous, une erreur est survenue, veuillez recharger la page"
          );
          break;
      }
    }
  }, [checkPaidProcess]);

  const handleCreditsPaiement = () => {
    postCredits(`/purchase-by-credits`, { user: user, useCredits: useCredits });
  };
  const handleSubscriptionProcess = () => {
    postTransfer(`/subscriber_purchase`);
  };
  const handleTransferPaiement = () => {
    postTransfer(`/purchase-by-transfer`, {
      user: user,
      useCredits: useCredits,
    });
  };

  // mise a jour du panier apres transaction par crédit ou virement
  useEffect(() => {
    if (creditsProcess) {
      setCart({
        ...cart,
        available_credits: creditsProcess.available_credits,
        total_price: 0,
        total_price_left: 0,
        candidates: [],
      });
      setOpenDialog(false);
      setUseCredits(false);
      toast.success(creditsProcess.message);
    }
    if (transferProcess) {
      setCart({
        ...cart,
        total_price: 0,
        candidates: [],
      });
      setOpenTransfer(false);
      setUseCredits(false);
      setSubProcess(false);
      toast.success(transferProcess.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creditsProcess, transferProcess]);

  return (
    <Fragment>
      <Button
        variant="outlined"
        onClick={() => setOpenDrawer(!openDrawer)}
        sx={{
          "&:hover": {
            color: "rgba(0, 0, 0, 1) !important",
            svg: { color: "rgba(0, 0, 0, 1) !important" },
          },
        }}
      >
        Panier
        <Badge badgeContent={cart?.candidates?.length} color="primary">
          <ShoppingCartIcon />
        </Badge>
      </Button>
      {cart && (
        <Drawer
          id="cart-drawer"
          open={openDrawer}
          onClose={() => setOpenDrawer(!openDrawer)}
          anchor="right"
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h3">Mon panier</Typography>
            <Button onClick={() => setOpenDrawer(!openDrawer)}>
              <CloseIcon />
            </Button>
          </Stack>
          <Typography variant="body2">
            {cart?.candidates?.length} CV actuellement dans votre panier
          </Typography>

          <Stack direction="row" justifyContent="space-between" mt={1}>
            <Typography variant="h6">Contenu du panier</Typography>
            <Typography variant="h6">Prix</Typography>
          </Stack>

          <Stack
            gap={1}
            sx={{
              height: useCredits ? "calc(55vh - 97px)" : "55vh",
              overflowY: "auto",
            }}
          >
            {cart?.candidates?.map((cv, index) => (
              <MenuItem key={index}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  sx={{ width: "100%" }}
                >
                  <Stack direction="row" alignItems="center" gap={1}>
                    <CloseIcon
                      onClick={() => {
                        if (user) {
                          removeCandFromCart(cv);
                        } else {
                          setCart({
                            ...cart,
                            candidates: cart.candidates.filter(
                              (item) => item?.candidate_id !== cv?.candidate_id
                            ),
                          });
                        }
                      }}
                    />
                    <Avatar
                      src={cv.avatar}
                      alt={cv.firstname}
                      sx={{
                        width: "50px !important",
                        height: "50px !important",
                      }}
                    />
                    <Stack>
                      <Typography variant="p">
                        {cv.firstname} {cv.lastname} -{" "}
                        {cv.jobs.join(", ").length > 20 ? (
                          <span>{cv.jobs.join(", ").substring(0, 20)}...</span>
                        ) : (
                          cv.jobs.join(", ")
                        )}
                      </Typography>
                      <Link
                        className="link-drawer"
                        to={`/resumes/${cv.candidate_id}/?from-cart`}
                        onClick={() => {
                          setOpenDrawer(!openDrawer);
                        }}
                      >
                        Voir le profil
                      </Link>
                    </Stack>
                  </Stack>
                  <Stack direction="row" gap={1} alignItems="center">
                    <Typography variant="body2">100 € HT</Typography>
                  </Stack>
                </Stack>
              </MenuItem>
            ))}
          </Stack>

          <Stack
            direction="column"
            justifyContent="space-between"
            sx={{ height: "200px" }}
          >
            <Stack
              direction={{ xs: "column", sm: "row" }}
              justifyContent="space-between"
            >
              <Stack direction="row" gap={1} alignItems="flex-end">
                <Typography variant="h6">Vos crédits:</Typography>
                <Typography variant="body2">
                  {user ? cart.available_credits : 0}
                </Typography>
                <img src={coin} alt="coin" style={{ width: "40px" }} />
              </Stack>
              <Stack direction="row" gap={1} alignItems="flex-end">
                <Typography variant="h6">Prix total</Typography>
                <Typography variant="body2">
                  {user?.is_subscriber
                    ? 0
                    : user
                    ? cart.total_price
                    : cart.candidates?.length * 100}
                  € HT
                </Typography>
              </Stack>
            </Stack>

            {cart.candidates?.length > 0 ? (
              <Fragment>
                <Stack my={2}>
                  <FormGroup
                    sx={{ alignItems: "center", flexWrap: "nowrap", gap: 1 }}
                    row={true}
                  >
                    <Checkbox
                      sx={{ width: "fit-content", alignSelf: "start" }}
                      disabled={
                        user
                          ? cart.available_credits === 0 ||
                            user?.is_subscriber === true
                          : true
                      }
                      checked={useCredits}
                      onChange={(e) => setUseCredits(e.target.checked)}
                    />

                    <Typography variant="body1" color="initial">
                      Utiliser votre solde de crédits !
                    </Typography>
                    <img src={coin} alt="coin" style={{ width: "20px" }} />
                  </FormGroup>

                  {/* <LockCart /> */}
                  {useCredits && (
                    <Grid
                      container
                      sx={{
                        borde: "solid red 1px",
                        width: "65%",
                        alignSelf: "end",
                      }}
                    >
                      <Grid item xs={10}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: "15px !important",
                            textAlign: "end !important",
                          }}
                          color="initial"
                        >
                          Montant déduit (1
                          <img
                            src={coin}
                            alt="coin"
                            style={{ width: "20px", translate: "0 30%" }}
                          />
                          <strong style={{ fontSize: "14px" }}> = </strong>1
                          CV):
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: "15px !important",
                            fontWeight: "300 !important",
                            textAlign: "end !important",
                            whiteSpace: "nowrap !important",
                          }}
                          color="initial"
                        >
                          -{user ? cart.total_price - cart.total_price_left : 0}
                          € HT
                        </Typography>
                      </Grid>

                      <Grid item xs={10}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: "15px !important",
                            textAlign: "end !important",
                          }}
                          color="initial"
                        >
                          Crédits restant après achat:
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: "15px !important",
                            textAlign: "end !important",
                            fontWeight: "300 !important",
                          }}
                          color="initial"
                        >
                          {user ? cart.credits_left : 0}
                        </Typography>
                      </Grid>

                      <Grid item xs={10} sx={{ paddingRight: "8px" }}>
                        <Typography
                          variant="h6"
                          color="initial"
                          sx={{
                            textAlign: "end !important",
                            fontWeight: "700 !important",
                          }}
                        >
                          Montant Total:
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontWeight: "700 !important",
                            textAlign: "end !important",
                            whiteSpace: "nowrap !important",
                          }}
                          color="initial"
                        >
                          {user ? cart.total_price_left : 0}€ HT
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Stack>
                {user?.is_subscriber && (
                  <Typography
                    variant="body2"
                    color="initial"
                    sx={{ fontStyle: "italic" }}
                  >
                    En tant qu'abonné, vous n'avez rien à dépenser !
                  </Typography>
                )}
                {user ? (
                  <Button
                    variant="contained"
                    onClick={handleInitCheck}
                    disabled={loadingInitCheck}
                  >
                    {cart?.available_credits - cart?.candidates.length >= 0 &&
                    useCredits
                      ? "Débloquer les CVs"
                      : user?.is_subscriber
                      ? "Ajouter ces profils à mes CV"
                      : "Procéder au paiement"}
                  </Button>
                ) : (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    gap={1}
                    flexWrap="wrap"
                  >
                    <Button
                      variant="contained"
                      onClick={() => {
                        setOpenDrawer(!openDrawer);
                        navigate("/login");
                      }}
                    >
                      Connectez-vous
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setOpenDrawer(!openDrawer);
                        navigate("/signup");
                      }}
                    >
                      Créer un compte
                    </Button>
                  </Stack>
                )}
                {!user?.is_subscriber && (
                  <Stack
                    py={2}
                    direction={{ xs: "column", sm: "row" }}
                    gap={{ xs: 1, md: 0 }}
                  >
                    <Stack alignItems="center" justifyItems="center" gap="10px">
                      <Typography
                        variant="body1"
                        align="center"
                        sx={{
                          textAlign: "center !important",
                          fontSize: "12px !important",
                          lineHeight: "15px !important",
                        }}
                      >
                        Abonnez-vous pour obtenir directement les CV de votre
                        choix !
                      </Typography>
                      <Link
                        to="/offers#offers-list-section"
                        className="link-drawer"
                      >
                        Découvrir les abonnements
                      </Link>
                    </Stack>
                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{
                        borderColor: "black !important",
                        borderWidth: "1px !important",
                        mx: "5px",
                        opacity: "0.5",
                      }}
                    />
                    <Stack alignItems="center" justifyItems="center" gap="10px">
                      <Typography
                        variant="body1"
                        sx={{
                          textAlign: "center !important",
                          fontSize: "12px !important",
                          lineHeight: "15px !important",
                        }}
                      >
                        Vous souhaitez régler par virement bancaire ? C’est
                        possible !
                      </Typography>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => handleInitCheck(undefined, true)}
                        disabled={loadingInitCheck}
                      >
                        Régler par virement
                      </Button>
                    </Stack>
                  </Stack>
                )}
              </Fragment>
            ) : (
              <Typography variant="body1">
                Ajouter au moins 1 CV à votre panier pour continuer
              </Typography>
            )}
          </Stack>
        </Drawer>
      )}

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Fragment>
          <PaimentProcess useCredits={useCredits} />
        </Fragment>
      </Modal>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle
          sx={{
            color: "rgb(0, 83, 157) !important",
            p: 0,
            paddingLeft: "24px",
          }}
        >
          Utilisation des crédits
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Vous allez utiliser vos crédits pour payer votre commande.
            Voulez-vous continuer ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setOpenDialog(false)}
            disabled={loadingCredits}
          >
            Annuler
          </Button>
          <Button
            variant="contained"
            onClick={handleCreditsPaiement}
            autoFocus
            disabled={loadingCredits}
          >
            Débloquer les CVs
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openTransfer} onClose={() => setOpenTransfer(false)}>
        <DialogTitle
          sx={{
            color: "rgb(0, 83, 157) !important",
            p: 0,
            paddingLeft: "24px",
          }}
        >
          Procéder au règlement par virement bancaire:
        </DialogTitle>
        <DialogContent>
          <Typography
            component="ol"
            variant="body1"
            sx={{
              marginLeft: "24px",
              lineHeight: "2",
            }}
          >
            <li>Ajouter le RIB ci-dessous en bénéficiaire</li>
            <li>
              Effectuer un virement de{" "}
              <Typography component="span" sx={{ fontWeight: "bold" }}>
                {transferAmount * 1.2}€
              </Typography>{" "}
              ({transferAmount} + la TVA)
            </li>
            <li>Confirmer le règlement en cliquant sur "Confirmer"</li>
            <li>
              Dès lors que nous recevrons votre virement, nous débloquerons vos
              CV !
            </li>
            <li>
              Indiquer la référence suivante:{" "}
              <Typography component="span" sx={{ fontWeight: "bold" }}>
                #{cart?.candidates?.length}-{transferAmount}-{user?.id}
              </Typography>
            </li>
          </Typography>
          <Typography
            variant="body1"
            sx={{
              lineHeight: "1.7",
              marginLeft: "10px",
              marginTop: "15px",
            }}
          >
            <strong>RIB:</strong>
          </Typography>
          <Typography
            component="ul"
            variant="body1"
            sx={{
              listStyle: "none",
              listStylePosition: "inside",
              lineHeight: "1.7",
              marginLeft: "30px",
            }}
          >
            <li>Nom: Talents et paillettes</li>
            <li>Iban: FR76 3000 6000 0112 3456 7890 189</li>
            <li>Bic: AGRIFRPPXXX</li>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setOpenTransfer(false)}
            disabled={loadingCredits}
          >
            Annuler
          </Button>
          <Button
            variant="contained"
            onClick={handleTransferPaiement}
            autoFocus
            disabled={loadingCredits}
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={subProcess} onClose={() => setSubProcess(false)}>
        <DialogTitle
          sx={{
            color: "rgb(0, 83, 157) !important",
            p: 0,
            paddingLeft: "24px",
          }}
        >
          Débloquer ces CV
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Êtes vous sûr de vouloir ajouter ces CV à votre galerie de CV ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setSubProcess(false)}
            disabled={loadingCredits}
          >
            Annuler
          </Button>
          <Button
            variant="contained"
            onClick={handleSubscriptionProcess}
            autoFocus
            disabled={loadingCredits}
          >
            Débloquer les CVs
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default CartDrawer;
