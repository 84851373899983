import React, { Fragment, useEffect, useState } from "react";
import {
  background_header_cvtheque,
  background_left_pail,
  background_right_pail,
} from "../../config/config";
import { Stack } from "@mui/material";

const FullBackground = ({ children }) => {
  const [showMessageHeight, setShowMessageHeight] = useState("0");

  useEffect(() => {
    const showMessage = document.getElementById("show-message");
    if (showMessage) {
      setShowMessageHeight(showMessage?.offsetHeight);
    }
  }, []);

  return (
    <Fragment>
      {showMessageHeight && (
        <>
          <Stack
            sx={{
              backgroundImage: `url(${background_header_cvtheque})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: `calc(630px + ${showMessageHeight}px)`,
              position: "absolute",
              width: "100vw",
              zIndex: "-1",
              top: "0",
              "& > img": {
                opacity: { xs: 0.5, md: 1 },
              },
            }}
          >
            <img
              src={background_left_pail}
              alt="background"
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "20vw",
                minWidth: "200px",
                zIndex: -1,
              }}
            />
            <img
              src={background_right_pail}
              alt="background"
              style={{
                position: "absolute",
                zIndex: -1,
                width: "20vw",
                minWidth: "200px",
                right: 0,
                bottom: "100px",
              }}
            />
          </Stack>
          {children}
        </>
      )}
    </Fragment>
  );
};

export default FullBackground;
