import React, { Fragment } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Font,
} from "@react-pdf/renderer";
import { PDFDownloadLink, Image } from "@react-pdf/renderer";

import Pdf_header_bg from "../../../utils/images/pdf_bg_header.png";
import Pdf_email from "../../../utils/images/pdf_email.png";
import Pdf_phone from "../../../utils/images/pdf_phone.png";
import Pdf_deco from "../../../utils/images/pdf_deco.png";
import M1 from "../../../utils/images/avatars/m1.png";
import M2 from "../../../utils/images/avatars/m2.png";
import M3 from "../../../utils/images/avatars/m3.png";
import M4 from "../../../utils/images/avatars/m4.png";
import M5 from "../../../utils/images/avatars/m5.png";
import M6 from "../../../utils/images/avatars/m6.png";
import M7 from "../../../utils/images/avatars/m7.png";
import M8 from "../../../utils/images/avatars/m8.png";
import W1 from "../../../utils/images/avatars/w1.png";
import W2 from "../../../utils/images/avatars/w2.png";
import W3 from "../../../utils/images/avatars/w3.png";
import W4 from "../../../utils/images/avatars/w4.png";
import W5 from "../../../utils/images/avatars/w5.png";
import W6 from "../../../utils/images/avatars/w6.png";
import W7 from "../../../utils/images/avatars/w7.png";
import W8 from "../../../utils/images/avatars/w8.png";
import Default from "../../../utils/images/avatars/default.png";
import { formatDate } from "../../../utils/helpers";

const avatars = [
  {
    key: "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/man_5.svg",
    value: M1,
  },
  {
    key: "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/man_2.svg",
    value: M2,
  },
  {
    key: "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/man_7.svg",
    value: M3,
  },
  {
    key: "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/man_1.svg",
    value: M4,
  },
  {
    key: "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/man_6.svg",
    value: M5,
  },
  {
    key: "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/man_4.svg",
    value: M6,
  },
  {
    key: "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/man_8.svg",
    value: M7,
  },
  {
    key: "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/man_3.svg",
    value: M8,
  },
  {
    key: "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/woman_1.svg",
    value: W1,
  },
  {
    key: "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/woman_7.svg",
    value: W2,
  },
  {
    key: "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/woman_8.svg",
    value: W3,
  },
  {
    key: "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/woman_4.svg",
    value: W4,
  },
  {
    key: "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/woman_3.svg",
    value: W5,
  },
  {
    key: "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/woman_6.svg",
    value: W6,
  },
  {
    key: "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/woman_2.svg",
    value: W7,
  },
  {
    key: "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/woman_5.svg",
    value: W8,
  },
];

/**
 * Découpe le tableau en plusieurs petit tableau
 *
 * @array {Array} Tableau de String
 * @size {Number} Taille des parties du tableau
 * @returns {Array} - Tableau de tableau
 */
const eachSlice = (array, size) =>
  Array.from({ length: Math.ceil(array.length / size) }, (_, i) =>
    array.slice(i * size, i * size + size)
  );

Font.register({
  family: "Gloock",
  src: "/font/Gloock-Regular.ttf",
});
Font.register({
  family: "Raleway",
  fonts: [
    { src: "/font/Raleway-Regular.ttf", fontWeight: "400" },
    { src: "/font/Raleway-Medium.ttf", fontWeight: "500" },
  ],
});
// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#f7fbfd",
    width: "1000px",
    fontSize: "9px",
    color: "rgb(0, 83, 157)",
    padding: "15px 0",
    fontFamily: "Raleway",
  },
  gloock: {
    fontFamily: "Gloock",
  },
  raleway: {
    fontFamily: "Raleway",
    fontWeight: "400",
  },
  header__bg: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
  },
  page__bg: {
    position: "absolute",
    top: "50%",
    left: "0",
    width: "100%",
  },
  title_deco: {
    position: "absolute",
    top: "-3px",
    left: "-15px",
    width: "20px",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    marginTop: "5px",
  },
  profile_picture: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "50%",
  },
  profile_picture_container: {
    width: "180px",
    height: "180px",
    borderRadius: "50%",
    overflow: "hidden",
    border: "10px solid white",
    marginLeft: "20px",
    backgroundColor: "rgb(255, 255, 255, 0.8)",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#eef9ff",
    width: "100%",
    padding: "10px 15px",
    marginLeft: "20px",
  },
  main__job: {
    backgroundColor: "rgb(0, 83, 157)",
    padding: "5px 8px",
  },
  padding_sm: {
    padding: "5px 0",
  },
  body: {
    width: "90%",
    border: "2px solid rgb(255, 194, 13)",
    padding: "3px 20px",
    margin: "8px auto",
    backgroundColor: "#fff",
  },
  title__light: {
    marginTop: "5px",
    backgroundColor: "#69acdf",
  },
  title__dark: {
    marginTop: "9px",
    backgroundColor: "rgb(0, 83, 157)",
  },
  title_common: {
    color: "white",
    padding: "2px 7px",
    marginBottom: "7px",
    marginTop: "7px",
    fontFamily: "Gloock",
  },
  body__section: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  chip__container: {
    marginBottom: "2px",
  },
  chip__common: {
    padding: "3px 7px",
    borderRadius: "10px 50% 10px 50%",
    margin: "1px",
  },
  chip: {
    backgroundColor: "#1976d2",
    color: "white",
  },
  chip_variant: {
    backgroundColor: "rgb(255, 194, 13)",
    color: "black",
  },
  text: {
    color: "rgb(0, 83, 157)",
    margin: "0",
    padding: "0",
  },
});

// // *** PROFILE TEST EN PHASE DE DEV ***
// const resume = {
//   id: 1,
//   candidate_id: 1,
//   firstname: "Alice",
//   lastname: "Durand",
//   email: "alice.durand@example.com",
//   phone_number: "0123456789",
//   address: "123 Rue de Paris",
//   zip_code: 75001,
//   city: "Paris",
//   country: "France",
//   citizenship_status: "EU",
//   industries: [
//     "Industries",
//     "Conseil, Orientation et Formation",
//     "Gestion administrative et Ressources Humaines",
//     "Industries",
//     "Gestion administrative et Ressources Humaines",
//     "Gestion administrative et Ressources Humaines",
//     "Conseil, Orientation et Formation",
//     "Industries",
//     "Conseil, Orientation et Formation",
//   ],
//   jobs: [
//     "Agent de maintenance industrielle",
//     "Conseiller en formation",
//     "Conseiller en formation",
//     "Autre (Industries)",
//     "Autre (Gestion administrative et Ressources Humaines)",
//     "Conseiller en formation",
//     "Autre (Industries)",
//     "Autre (Gestion administrative et Ressources Humaines)",
//   ],
//   contract_type: ["CDI", "CDD", "Stage", "Alternance"],
//   hook_sentences: "I am a passionate developer with a strong work ethic",
//   social_skills: ["Motivation", "Capacité à apprendre"],
//   strengths: [
//     "Créativité, ingéniosité, et originalité",
//     "Perspective, Sagesse",
//   ],
//   it_skills: "Proficient in Ruby on Rails, JavaScript",
//   languages: "English, French",
//   passions: "Coding, Photography",
//   geographical_mobility: '["Arras (62000)", "Cayenne (97300)"]',
//   salary_expectations: "50000 annually",
//   specifics_points: "Interested in remote opportunities",
//   avatar:
//     "https://rudigis-project.s3.eu-west-3.amazonaws.com/talents+et+paillettes+/man_8.svg",
//   work_experiences: [
//     [
//       "1",
//       "Tech Solutions",
//       "Senior Developer",
//       "Senior software developer with a focus on mobile applications and backend development. Worked on various projects and",
//       "2018",
//       "2022",
//     ],
//     [
//       "2",
//       "Innovatech",
//       "Junior Developer",
//       "Junior software developer with a focus on web development. Worked on various projects and",
//       "2015",
//       "2018",
//     ],
//   ],
//   educations: [
//     ["1", "University of Technology", "Computer Science", "2010", "2014"],
//     ["2", "University of Technology", "Computer Science", "2010", "2014"],
//     ["3", "University of Technology", "Computer Science", "2010", "2014"],
//     ["4", "University of Technology", "Computer Science", "2010", "2014"],
//   ],
// };
// const profile_picture = "";

/**
 * Composant pour afficher un titre avec deux variantes de style (light ou dark).
 *
 * @param {Boolean} [title_light=true] - Définit si le style du titre doit être clair (light) ou sombre (dark).
 * @param {React.ReactNode} children - Le contenu du titre à afficher (peut être une chaîne ou des éléments JSX).
 * @returns {JSX.Element} - Un conteneur avec un texte stylisé représentant le titre.
 */
const Title = ({ title_light = true, children }) => {
  return (
    <View style={styles.body__section}>
      {!title_light && <Image src={Pdf_deco} style={styles.title_deco} />}
      <Text
        style={[
          styles.padding_sm,
          styles.title_common,
          title_light ? styles.title__light : styles.title__dark,
        ]}
      >
        {children}
      </Text>
    </View>
  );
};

/**
 * Flex-wrap ne fonctionnant pas sur react-pdf, pour ne pas dépasser le conteneur ou que tout soit
 * compacté, on coupe le tableau en plusieurs parties puis on map chaque partie dans une section
 *
 * @content {Array} Tableau de String
 * @chip {Number} Variant d'affichage des chips: 0 == Bleu, 1 == Jaune, 2 == Texte
 * @returns {JSX.Element} - Conteneur avec des sous sections de String
 */
const SlicedContent = ({ content, chip = 0 }) => {
  return (
    <>
      <View style={[styles.body__section, styles.chip__container]}>
        {content.map((job, job_index) => (
          <Text
            key={job_index}
            style={[
              styles.chip__common,
              chip === 0
                ? styles.chip
                : chip === 1
                ? styles.chip_variant
                : styles.text,
            ]}
          >
            {job}
            {chip === 2 && job_index !== content.length - 1 && " - "}
          </Text>
        ))}
      </View>
    </>
  );
};

/**
 * Affiche du contenu textuel dans un conteneur stylisé.
 *
 * @param {String} content - Chaine de caractères à afficher.
 * @returns {JSX.Element} - Conteneur qui affiche le texte fourni avec des styles spécifiques.
 */
const DisplayContent = ({ content }) => {
  return (
    <View style={[styles.body__section, styles.chip__container]}>
      <Text style={styles.text}>{content}</Text>
    </View>
  );
};

/**
 * Variant pour afficher des tableaux d'objets.
 *
 * @param {Array<Object>} work_experience - Tableau d'objets, chaque objet contient les propriétés start_year, end_year, et un troisième champ non spécifié.
 * @returns {JSX.Element} - Conteneur qui affiche chaque élément de l'objet en ligne séparé par un tiret.
 */

const WorkExperiences = ({ work_experience }) => {
  return (
    <>
      {work_experience.map((we, we_index) => (
        <Text key={we_index} style={styles.text}>
          <Text>
            {formatDate(we.start_year)} -{" "}
            {we.end_year ? formatDate(we.end_year) : "Aujourd'hui"}
          </Text>{" "}
          <Text style={styles.text}>
            - {we.company} - {we.job_position} - {we.description}
          </Text>
        </Text>
      ))}
    </>
  );
};

/**
 * Variant pour afficher des tableaux d'objets.
 *
 * @param {Array<Object>} educations - Tableau d'objets, chaque objet contient les propriétés start_year, end_year, et un troisième champ non spécifié.
 * @returns {JSX.Element} - Conteneur qui affiche chaque élément de l'objet en ligne séparé par un tiret.
 */

const Educations = ({ educations }) => {
  return (
    <>
      {educations.map((edu, edu_index) => (
        <View key={edu_index}>
          <Text style={styles.text}>
            {formatDate(edu.start_year)} - {formatDate(edu.end_year)} -{" "}
            {edu.school} - {edu.training_course}
          </Text>
        </View>
      ))}
    </>
  );
};
/**
 * Affiche le titre, et le body en fonction des paramètres fournis.
 *
 * @param {String} title - Titre de la section
 * @param {Boolean} [title_light=true] - Définit si le titre utilise le style léger ou sombre
 * @param {String[]|String} content - Tableau de chaînes de caractères ou une chaîne unique
 * @param {Boolean} [sliced=false] - Si `true`, le contenu est un tableau à diviser et afficher sous forme de chips
 * @param {Boolean} [display=false] - Si `true`, le contenu est une chaîne de texte à afficher directement
 * @param {Number} [chip=0] - Variante d'affichage des chips : 0 = Bleu, 1 = Jaune, 2 = Texte brut
 * @param {JSX.Element} [children] - Enfants supplémentaires à afficher dans la section
 * @returns {JSX.Element} - Conteneur avec des sous-sections pour afficher le contenu
 */
const BodySection = ({
  title,
  title_light = true,
  content,
  sliced = false,
  display = false,
  chip = 0,
  children,
}) => {
  return (
    <View wrap={false}>
      <Title title_light={title_light}>{title}</Title>
      {sliced && <SlicedContent content={content} chip={chip} />}
      {display && <DisplayContent content={content} />}
      {children && children}
    </View>
  );
};

// Create Document Component
const ResumePdf = ({ resume, profile_picture }) => (
  <Document style={{ position: "relative" }}>
    <Page size="A4" style={styles.page}>
      <Image src={Pdf_header_bg} style={styles.header__bg} />
      {/* <Image src={Pdf_bg} style={styles.page__bg} /> */}
      <View style={styles.header}>
        <View style={styles.profile_picture_container}>
          {profile_picture ? (
            <Image
              src={profile_picture + `?cache_buster=${new Date().getTime()}`}
              alt={resume.firstname}
              style={styles.profile_picture}
            />
          ) : resume.avatar ? (
            <Image
              src={avatars.find((a) => a.key === resume.avatar).value}
              alt={resume.firstname}
              style={styles.avatar}
            />
          ) : (
            <Image src={Default} alt={resume.firstname} style={styles.avatar} />
          )}
        </View>
        <View style={{ width: "45%" }}>
          <View style={styles.main}>
            <Text style={[styles.padding_sm, styles.gloock]}>
              {resume.firstname} {resume.lastname}
            </Text>
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Text
                style={[styles.main__job, styles.gloock, { color: "white" }]}
              >
                {resume.jobs[0]}
              </Text>
            </View>
            <Text style={styles.padding_sm}>
              {resume.address}, {resume.city}, ({resume.zip_code})
            </Text>
            <Text style={styles.padding_sm}>
              <Image src={Pdf_phone} alt="phone icon" /> {resume.phone_number}
            </Text>
            <Text style={styles.padding_sm}>
              <Image src={Pdf_email} alt="phone icon" /> {resume.email}
            </Text>
            <Text style={styles.padding_sm}>{resume.hook_sentences}</Text>
          </View>
        </View>
      </View>
      <View style={styles.body}>
        <BodySection
          title="Secteur(s) d'activité(e)"
          title_light={true}
          content={resume.industries}
          sliced={true}
          chip={0}
        />
        <BodySection
          title="Métier(s)"
          title_light={false}
          content={resume.jobs}
          sliced={true}
          chip={2}
        />
        <BodySection
          title="Type(s) de contrat(s)"
          title_light={true}
          content={resume.contract_type}
          sliced={true}
          chip={2}
        />
        <BodySection title="Expériences professionnelles" title_light={false}>
          <WorkExperiences work_experience={resume.work_experiences} />
        </BodySection>
        <BodySection title="Formations" title_light={true}>
          <Educations educations={resume.educations} />
        </BodySection>
        <BodySection
          title="Savoir(s) être(s)"
          title_light={false}
          content={resume.social_skills}
          sliced={true}
          chip={1}
        />
        <BodySection
          title="Forces de caractères"
          title_light={true}
          content={resume.strengths}
          sliced={true}
          chip={1}
        />
        <BodySection
          title="Compétence(s) informatique(s)"
          title_light={false}
          content={resume.it_skills}
          display={true}
          chip={2}
        />
        <BodySection
          title="Point spécifique"
          title_light={true}
          content={resume.specifics_points}
          display={true}
          chip={2}
        />
        {resume.salary_expectations && (
          <BodySection
            title="Prétentions salariales"
            title_light={false}
            content={`${resume.salary_expectations.split(" ")[0]} ${
              resume.salary_expectations.split(" ")[1] === "0"
                ? "Brut/Annuel"
                : "Net/Mensuel"
            }`}
            display={true}
            chip={2}
          />
        )}
        <BodySection
          title="Langue"
          title_light={true}
          content={resume.languages}
          display={true}
          chip={2}
        />
        <BodySection
          title="Ses passions"
          title_light={false}
          content={resume.passions}
          display={true}
          chip={2}
        />
        <BodySection
          title="Mobilité géographique"
          title_light={true}
          content={JSON.parse(resume.geographical_mobility).join(" - ")}
          display={true}
          chip={2}
        />
      </View>
    </Page>
  </Document>
);

const DownloadPdf = ({ resume, profile_picture }) => {
  return (
    <PDFDownloadLink
      // style={{ position: "absolute", visibility: "hidden" }}
      // className={`download-resume-pdf-${resume.id}`}
      document={<ResumePdf resume={resume} profile_picture={profile_picture} />}
      fileName={`CV_${resume.lastname}_${resume.firstname}.pdf`}
    >
      Télécharger
    </PDFDownloadLink>
  );
};

// *** VIEWER EN PHASE DE DEV ***
// const DownloadPdf = () => {
//   // console.log(resume, profile_picture);
//   const avatar = avatars.find((a) => a.key === resume?.avatar);
//   return (
//     <PDFViewer width="100%" style={{ minHeight: "100vh" }}>
//       <ResumePdf resume={resume} profile_picture={profile_picture} />
//     </PDFViewer>
//   );
// };

export default DownloadPdf;
