import React, { useEffect, useState } from "react";
import { Stack, CircularProgress, Button } from "@mui/material";

export const Loader = ({ style }) => {
  return (
    <Stack
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, 0%)",
        zIndex: "5",
        width: "fit-content",
        ...style,
      }}
    >
      <CircularProgress color="primary" />
    </Stack>
  );
};

export const ButtonLoader = ({ button_label, unload, callback }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (unload) {
      setLoading(false);
    }
  }, [unload]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            setLoading(true);
            callback();
          }}
        >
          {button_label}
        </Button>
      )}
    </>
  );
};
