import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

const styles = {
  tHeadCell: {
    borderRight: "1px solid rgba(224, 224, 224, 1)",
    borderBottomWidth: "0px",
    minWidth: "100px !important",
    textAlign: "center",
  },
  tBodyCell: {
    textAlign: "right !important",
    borderBottomWidth: "1px !important",
  },
};

const Turnover = ({ turnover, current_year_turnover }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {turnover.map((ca, i) => (
              <TableCell sx={styles.tHeadCell} key={`month-${i}`}>
                {ca.month}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {turnover.map((ca, i) => (
              <TableCell
                sx={styles.tBodyCell}
                style={styles.tHeadCell}
                key={`total-${i}`}
              >
                {ca.total}€
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell colSpan={12} align="right">
              Total Année: {current_year_turnover}€
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Turnover;
